import { useCallback, useEffect, useMemo, useState } from 'react';
let elementSizeHooks = [];
export const updateAllElementsSizes = () => {
    for (const listener of elementSizeHooks) {
        listener();
    }
};
export const useElementSize = (ref, options) => {
    const [size, setSize] = useState(null);
    const observer = useMemo(() => {
        if (typeof ResizeObserver === 'undefined') {
            return null;
        }
        return new ResizeObserver((entries) => {
            // The contentRect returns the width without any `scale()`'s being applied. The height is wrong
            const { contentRect } = entries[0];
            // The clientRect returns the size with `scale()` being applied.
            const newSize = entries[0].target.getClientRects();
            if (!newSize || !newSize[0]) {
                setSize(null);
                return;
            }
            const probableCssParentScale = newSize[0].width / contentRect.width;
            const width = options.shouldApplyCssTransforms
                ? newSize[0].width
                : newSize[0].width * (1 / probableCssParentScale);
            const height = options.shouldApplyCssTransforms
                ? newSize[0].height
                : newSize[0].height * (1 / probableCssParentScale);
            setSize({
                width,
                height,
                left: newSize[0].x,
                top: newSize[0].y,
                windowSize: {
                    height: window.innerHeight,
                    width: window.innerWidth,
                },
            });
        });
    }, [options.shouldApplyCssTransforms]);
    const updateSize = useCallback(() => {
        if (!ref.current) {
            return;
        }
        const rect = ref.current.getClientRects();
        if (!rect[0]) {
            setSize(null);
            return;
        }
        setSize({
            width: rect[0].width,
            height: rect[0].height,
            left: rect[0].x,
            top: rect[0].y,
            windowSize: {
                height: window.innerHeight,
                width: window.innerWidth,
            },
        });
    }, [ref]);
    useEffect(() => {
        if (!observer) {
            return;
        }
        updateSize();
        const { current } = ref;
        if (ref.current) {
            observer.observe(ref.current);
        }
        return () => {
            if (current) {
                observer.unobserve(current);
            }
        };
    }, [observer, ref, updateSize]);
    useEffect(() => {
        if (!options.triggerOnWindowResize) {
            return;
        }
        window.addEventListener('resize', updateSize);
        return () => {
            window.removeEventListener('resize', updateSize);
        };
    }, [options.triggerOnWindowResize, updateSize]);
    useEffect(() => {
        elementSizeHooks.push(updateSize);
        return () => {
            elementSizeHooks = elementSizeHooks.filter((e) => e !== updateSize);
        };
    }, [updateSize]);
    return size ? { ...size, refresh: updateSize } : null;
};
