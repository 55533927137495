import { useEffect, useRef } from 'react';
import { Internals } from 'remotion';
import { calculateNextFrame } from './calculate-next-frame';
import { usePlayer } from './use-player';
export const usePlayback = ({ loop, playbackRate, moveToBeginningWhenEnded, inFrame, outFrame, }) => {
    const frame = Internals.Timeline.useTimelinePosition();
    const config = Internals.useUnsafeVideoConfig();
    const { playing, pause, emitter } = usePlayer();
    const setFrame = Internals.Timeline.useTimelineSetFrame();
    const frameRef = useRef(frame);
    frameRef.current = frame;
    const lastTimeUpdateEvent = useRef(null);
    useEffect(() => {
        if (!config) {
            return;
        }
        if (!playing) {
            return;
        }
        let hasBeenStopped = false;
        let reqAnimFrameCall = null;
        const startedTime = performance.now();
        let framesAdvanced = 0;
        const stop = () => {
            hasBeenStopped = true;
            if (reqAnimFrameCall !== null) {
                cancelAnimationFrame(reqAnimFrameCall);
            }
        };
        const callback = () => {
            const time = performance.now() - startedTime;
            const actualLastFrame = outFrame !== null && outFrame !== void 0 ? outFrame : config.durationInFrames - 1;
            const actualFirstFrame = inFrame !== null && inFrame !== void 0 ? inFrame : 0;
            const { nextFrame, framesToAdvance, hasEnded } = calculateNextFrame({
                time,
                currentFrame: frameRef.current,
                playbackSpeed: playbackRate,
                fps: config.fps,
                actualFirstFrame,
                actualLastFrame,
                framesAdvanced,
                shouldLoop: loop,
            });
            framesAdvanced += framesToAdvance;
            if (nextFrame !== frameRef.current &&
                (!hasEnded || moveToBeginningWhenEnded)) {
                setFrame(nextFrame);
            }
            if (hasEnded) {
                stop();
                pause();
                emitter.dispatchEnded();
                return;
            }
            if (!hasBeenStopped) {
                reqAnimFrameCall = requestAnimationFrame(callback);
            }
        };
        reqAnimFrameCall = requestAnimationFrame(callback);
        return () => {
            stop();
        };
    }, [
        config,
        loop,
        pause,
        playing,
        setFrame,
        emitter,
        playbackRate,
        inFrame,
        outFrame,
        moveToBeginningWhenEnded,
    ]);
    useEffect(() => {
        const interval = setInterval(() => {
            if (lastTimeUpdateEvent.current === frameRef.current) {
                return;
            }
            emitter.dispatchTimeUpdate({ frame: frameRef.current });
            lastTimeUpdateEvent.current = frameRef.current;
        }, 250);
        return () => clearInterval(interval);
    }, [emitter]);
    useEffect(() => {
        emitter.dispatchFrameUpdate({ frame });
    }, [emitter, frame]);
};
