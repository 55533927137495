"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Video = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const get_environment_1 = require("../get-environment");
const loop_1 = require("../loop");
const Sequence_1 = require("../Sequence");
const use_video_config_1 = require("../use-video-config");
const validate_media_props_1 = require("../validate-media-props");
const validate_start_from_props_1 = require("../validate-start-from-props");
const duration_state_1 = require("./duration-state");
const VideoForDevelopment_1 = require("./VideoForDevelopment");
const VideoForRendering_1 = require("./VideoForRendering");
const VideoForwardingFunction = (props, ref) => {
    var _a;
    const { startFrom, endAt, ...otherProps } = props;
    const { loop, ...propsOtherThanLoop } = props;
    const { fps } = (0, use_video_config_1.useVideoConfig)();
    const environment = (0, get_environment_1.useRemotionEnvironment)();
    const { durations, setDurations } = (0, react_1.useContext)(duration_state_1.DurationsContext);
    if (typeof ref === 'string') {
        throw new Error('string refs are not supported');
    }
    if (typeof props.src !== 'string') {
        throw new TypeError(`The \`<Video>\` tag requires a string for \`src\`, but got ${JSON.stringify(props.src)} instead.`);
    }
    const onDuration = (0, react_1.useCallback)((src, durationInSeconds) => {
        setDurations({ type: 'got-duration', durationInSeconds, src });
    }, [setDurations]);
    if (loop && props.src && durations[props.src] !== undefined) {
        const naturalDuration = durations[props.src] * fps;
        const playbackRate = (_a = props.playbackRate) !== null && _a !== void 0 ? _a : 1;
        const durationInFrames = Math.floor(naturalDuration / playbackRate);
        return ((0, jsx_runtime_1.jsx)(loop_1.Loop, { durationInFrames: durationInFrames, children: (0, jsx_runtime_1.jsx)(exports.Video, { ...propsOtherThanLoop, ref: ref }) }));
    }
    if (typeof startFrom !== 'undefined' || typeof endAt !== 'undefined') {
        (0, validate_start_from_props_1.validateStartFromProps)(startFrom, endAt);
        const startFromFrameNo = startFrom !== null && startFrom !== void 0 ? startFrom : 0;
        const endAtFrameNo = endAt !== null && endAt !== void 0 ? endAt : Infinity;
        return ((0, jsx_runtime_1.jsx)(Sequence_1.Sequence, { layout: "none", from: 0 - startFromFrameNo, showInTimeline: false, durationInFrames: endAtFrameNo, children: (0, jsx_runtime_1.jsx)(exports.Video, { ...otherProps, ref: ref }) }));
    }
    (0, validate_media_props_1.validateMediaProps)(props, 'Video');
    if (environment === 'rendering') {
        return ((0, jsx_runtime_1.jsx)(VideoForRendering_1.VideoForRendering, { onDuration: onDuration, ...otherProps, ref: ref }));
    }
    return ((0, jsx_runtime_1.jsx)(VideoForDevelopment_1.VideoForDevelopment, { onlyWarnForMediaSeekingError: false, ...otherProps, ref: ref, onDuration: onDuration }));
};
const forward = react_1.forwardRef;
exports.Video = forward(VideoForwardingFunction);
