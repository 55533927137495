"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.appendVideoFragment = exports.useAppendVideoFragment = void 0;
const react_1 = require("react");
const toSeconds = (time, fps) => {
    return Math.round((time / fps) * 100) / 100;
};
const isSubsetOfDuration = (prevStartFrom, newStartFrom, prevDuration, newDuration) => {
    return (prevStartFrom <= newStartFrom &&
        prevStartFrom + prevDuration >= newStartFrom + newDuration);
};
const useAppendVideoFragment = ({ actualSrc: initialActualSrc, actualFrom: initialActualFrom, duration: initialDuration, fps, }) => {
    const actualFromRef = (0, react_1.useRef)(initialActualFrom);
    const actualDuration = (0, react_1.useRef)(initialDuration);
    const actualSrc = (0, react_1.useRef)(initialActualSrc);
    if (!isSubsetOfDuration || initialActualSrc !== actualSrc.current) {
        actualFromRef.current = initialActualFrom;
        actualDuration.current = initialDuration;
        actualSrc.current = initialActualSrc;
    }
    const appended = (0, exports.appendVideoFragment)({
        actualSrc: actualSrc.current,
        actualFrom: actualFromRef.current,
        duration: actualDuration.current,
        fps,
    });
    return appended;
};
exports.useAppendVideoFragment = useAppendVideoFragment;
// https://github.com/remotion-dev/remotion/issues/1655
const isIOSSafariCase = (actualSrc) => {
    return typeof window === 'undefined'
        ? false
        : /iP(ad|od|hone)/i.test(window.navigator.userAgent) &&
            Boolean(navigator.userAgent.match(/Version\/[\d.]+.*Safari/)) &&
            actualSrc.startsWith('blob:');
};
const appendVideoFragment = ({ actualSrc, actualFrom, duration, fps, }) => {
    var _a;
    if (isIOSSafariCase(actualSrc)) {
        return actualSrc;
    }
    if (actualSrc.startsWith('data:')) {
        return actualSrc;
    }
    const existingHash = Boolean(new URL(actualSrc, (_a = (typeof window === 'undefined' ? null : window.location.href)) !== null && _a !== void 0 ? _a : 'http://localhost:3000').hash);
    if (existingHash) {
        return actualSrc;
    }
    if (!Number.isFinite(actualFrom)) {
        return actualSrc;
    }
    actualSrc += `#t=${toSeconds(-actualFrom, fps)}`;
    if (!Number.isFinite(duration)) {
        return actualSrc;
    }
    actualSrc += `,${toSeconds(duration, fps)}`;
    return actualSrc;
};
exports.appendVideoFragment = appendVideoFragment;
