"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Clipper = void 0;
const react_1 = require("react");
const NativeLayers_1 = require("./NativeLayers");
const Clipper = ({ height, width, x, y }) => {
    const { setClipRegion } = (0, react_1.useContext)(NativeLayers_1.NativeLayersContext);
    (0, react_1.useEffect)(() => {
        setClipRegion((c) => {
            if (c === 'hide') {
                throw new Error('Cannot render <Clipper>, because another <Null> is already rendered');
            }
            if (c === null) {
                return { height, width, x, y };
            }
            throw new Error('Cannot render <Clipper>, because another component clipping the region was already rendered (most likely <Clipper>)');
        });
        return () => {
            setClipRegion(null);
        };
    }, [height, setClipRegion, width, x, y]);
    return null;
};
exports.Clipper = Clipper;
