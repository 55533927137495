"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AudioForRendering = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const absolute_src_1 = require("../absolute-src");
const CompositionManager_1 = require("../CompositionManager");
const delay_render_1 = require("../delay-render");
const get_environment_1 = require("../get-environment");
const random_1 = require("../random");
const Sequence_1 = require("../Sequence");
const timeline_position_state_1 = require("../timeline-position-state");
const use_current_frame_1 = require("../use-current-frame");
const volume_prop_1 = require("../volume-prop");
const use_audio_frame_1 = require("./use-audio-frame");
const AudioForRenderingRefForwardingFunction = (props, ref) => {
    const audioRef = (0, react_1.useRef)(null);
    const absoluteFrame = (0, timeline_position_state_1.useTimelinePosition)();
    const volumePropFrame = (0, use_audio_frame_1.useFrameForVolumeProp)();
    const frame = (0, use_current_frame_1.useCurrentFrame)();
    const sequenceContext = (0, react_1.useContext)(Sequence_1.SequenceContext);
    const { registerAsset, unregisterAsset } = (0, react_1.useContext)(CompositionManager_1.CompositionManager);
    const environment = (0, get_environment_1.useRemotionEnvironment)();
    // Generate a string that's as unique as possible for this asset
    // but at the same time the same on all threads
    const id = (0, react_1.useMemo)(() => {
        var _a;
        return `audio-${(0, random_1.random)((_a = props.src) !== null && _a !== void 0 ? _a : '')}-${sequenceContext === null || sequenceContext === void 0 ? void 0 : sequenceContext.relativeFrom}-${sequenceContext === null || sequenceContext === void 0 ? void 0 : sequenceContext.cumulatedFrom}-${sequenceContext === null || sequenceContext === void 0 ? void 0 : sequenceContext.durationInFrames}`;
    }, [props.src, sequenceContext]);
    const { volume: volumeProp, playbackRate, allowAmplificationDuringRender, onDuration, ...nativeProps } = props;
    const volume = (0, volume_prop_1.evaluateVolume)({
        volume: volumeProp,
        frame: volumePropFrame,
        mediaVolume: 1,
        allowAmplificationDuringRender: allowAmplificationDuringRender !== null && allowAmplificationDuringRender !== void 0 ? allowAmplificationDuringRender : false,
    });
    (0, react_1.useImperativeHandle)(ref, () => {
        return audioRef.current;
    }, []);
    (0, react_1.useEffect)(() => {
        var _a;
        if (!props.src) {
            throw new Error('No src passed');
        }
        if (!window.remotion_audioEnabled) {
            return;
        }
        if (props.muted) {
            return;
        }
        if (volume <= 0) {
            return;
        }
        registerAsset({
            type: 'audio',
            src: (0, absolute_src_1.getAbsoluteSrc)(props.src),
            id,
            frame: absoluteFrame,
            volume,
            mediaFrame: frame,
            playbackRate: (_a = props.playbackRate) !== null && _a !== void 0 ? _a : 1,
            allowAmplificationDuringRender: allowAmplificationDuringRender !== null && allowAmplificationDuringRender !== void 0 ? allowAmplificationDuringRender : false,
        });
        return () => unregisterAsset(id);
    }, [
        props.muted,
        props.src,
        registerAsset,
        absoluteFrame,
        id,
        unregisterAsset,
        volume,
        volumePropFrame,
        frame,
        playbackRate,
        props.playbackRate,
        allowAmplificationDuringRender,
    ]);
    const { src } = props;
    // If audio source switches, make new handle
    if (environment === 'rendering') {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        (0, react_1.useLayoutEffect)(() => {
            if (process.env.NODE_ENV === 'test') {
                return;
            }
            const newHandle = (0, delay_render_1.delayRender)('Loading <Audio> duration with src=' + src);
            const { current } = audioRef;
            const didLoad = () => {
                if (current === null || current === void 0 ? void 0 : current.duration) {
                    onDuration(src, current.duration);
                }
                (0, delay_render_1.continueRender)(newHandle);
            };
            if (current === null || current === void 0 ? void 0 : current.duration) {
                onDuration(src, current.duration);
                (0, delay_render_1.continueRender)(newHandle);
            }
            else {
                current === null || current === void 0 ? void 0 : current.addEventListener('loadedmetadata', didLoad, { once: true });
            }
            // If tag gets unmounted, clear pending handles because video metadata is not going to load
            return () => {
                current === null || current === void 0 ? void 0 : current.removeEventListener('loadedmetadata', didLoad);
                (0, delay_render_1.continueRender)(newHandle);
            };
        }, [src, onDuration]);
    }
    return (0, jsx_runtime_1.jsx)("audio", { ref: audioRef, ...nativeProps });
};
exports.AudioForRendering = (0, react_1.forwardRef)(AudioForRenderingRefForwardingFunction);
