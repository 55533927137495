"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRemotionEnvironment = exports.getRemotionEnvironment = void 0;
const is_player_1 = require("./is-player");
const getRemotionEnvironment = () => {
    if (process.env.NODE_ENV === 'production') {
        if (typeof window !== 'undefined' && window.remotion_isPlayer) {
            return 'player-production';
        }
        return 'rendering';
    }
    // The Vitest framework sets NODE_ENV as test.
    // Right now we don't need to treat it in a special
    // way which is good - defaulting to `rendering`.
    if (process.env.NODE_ENV === 'test') {
        return 'rendering';
    }
    if (typeof window !== 'undefined' && window.remotion_isPlayer) {
        return 'player-development';
    }
    return 'preview';
};
exports.getRemotionEnvironment = getRemotionEnvironment;
const useRemotionEnvironment = () => {
    const isPlayer = (0, is_player_1.useIsPlayer)();
    if (isPlayer) {
        if (process.env.NODE_ENV === 'production') {
            return 'player-production';
        }
        return 'player-development';
    }
    return (0, exports.getRemotionEnvironment)();
};
exports.useRemotionEnvironment = useRemotionEnvironment;
