var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { spring } from 'remotion';
const AnimationSpring = (withDefaultConfig) => (frame, fps, options = {}) => {
    const { start } = options, overrideSpringConfig = __rest(options, ["start"]);
    return spring({
        fps,
        frame: frame - (start !== null && start !== void 0 ? start : 0),
        durationInFrames: options.duration,
        config: Object.assign(Object.assign({}, withDefaultConfig), overrideSpringConfig),
    });
};
export default AnimationSpring;
