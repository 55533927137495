export const cancellablePromise = (promise) => {
    let isCanceled = false;
    const wrappedPromise = new Promise((resolve, reject) => {
        promise
            .then((value) => {
            if (isCanceled) {
                reject({ isCanceled, value });
                return;
            }
            resolve(value);
        })
            .catch((error) => {
            reject({ isCanceled, error });
        });
    });
    return {
        promise: wrappedPromise,
        cancel: () => {
            isCanceled = true;
        },
    };
};
