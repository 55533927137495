"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Experimental = exports.useCurrentFrame = exports.staticFile = exports.Series = exports.Sequence = exports.registerRoot = exports.random = exports.prefetch = exports.Loop = exports.interpolateColors = exports.getStaticFiles = exports.delayRender = exports.continueRender = exports.getInputProps = exports.Config = exports.cancelRender = void 0;
require("./asset-types");
const Clipper_1 = require("./Clipper");
const is_player_1 = require("./is-player");
const multiple_versions_warning_1 = require("./multiple-versions-warning");
const Null_1 = require("./Null");
(0, multiple_versions_warning_1.checkMultipleRemotionVersions)();
__exportStar(require("./AbsoluteFill"), exports);
__exportStar(require("./audio"), exports);
var cancel_render_1 = require("./cancel-render");
Object.defineProperty(exports, "cancelRender", { enumerable: true, get: function () { return cancel_render_1.cancelRender; } });
__exportStar(require("./Composition"), exports);
var config_1 = require("./config");
Object.defineProperty(exports, "Config", { enumerable: true, get: function () { return config_1.Config; } });
var input_props_1 = require("./config/input-props");
Object.defineProperty(exports, "getInputProps", { enumerable: true, get: function () { return input_props_1.getInputProps; } });
var delay_render_1 = require("./delay-render");
Object.defineProperty(exports, "continueRender", { enumerable: true, get: function () { return delay_render_1.continueRender; } });
Object.defineProperty(exports, "delayRender", { enumerable: true, get: function () { return delay_render_1.delayRender; } });
__exportStar(require("./easing"), exports);
__exportStar(require("./Folder"), exports);
__exportStar(require("./freeze"), exports);
var get_static_files_1 = require("./get-static-files");
Object.defineProperty(exports, "getStaticFiles", { enumerable: true, get: function () { return get_static_files_1.getStaticFiles; } });
__exportStar(require("./IFrame"), exports);
__exportStar(require("./Img"), exports);
__exportStar(require("./internals"), exports);
__exportStar(require("./interpolate"), exports);
var interpolate_colors_1 = require("./interpolate-colors");
Object.defineProperty(exports, "interpolateColors", { enumerable: true, get: function () { return interpolate_colors_1.interpolateColors; } });
var loop_1 = require("./loop");
Object.defineProperty(exports, "Loop", { enumerable: true, get: function () { return loop_1.Loop; } });
var prefetch_1 = require("./prefetch");
Object.defineProperty(exports, "prefetch", { enumerable: true, get: function () { return prefetch_1.prefetch; } });
var random_1 = require("./random");
Object.defineProperty(exports, "random", { enumerable: true, get: function () { return random_1.random; } });
var register_root_1 = require("./register-root");
Object.defineProperty(exports, "registerRoot", { enumerable: true, get: function () { return register_root_1.registerRoot; } });
var Sequence_1 = require("./Sequence");
Object.defineProperty(exports, "Sequence", { enumerable: true, get: function () { return Sequence_1.Sequence; } });
var series_1 = require("./series");
Object.defineProperty(exports, "Series", { enumerable: true, get: function () { return series_1.Series; } });
__exportStar(require("./spring"), exports);
var static_file_1 = require("./static-file");
Object.defineProperty(exports, "staticFile", { enumerable: true, get: function () { return static_file_1.staticFile; } });
__exportStar(require("./Still"), exports);
var use_current_frame_1 = require("./use-current-frame");
Object.defineProperty(exports, "useCurrentFrame", { enumerable: true, get: function () { return use_current_frame_1.useCurrentFrame; } });
__exportStar(require("./use-video-config"), exports);
__exportStar(require("./version"), exports);
__exportStar(require("./video"), exports);
__exportStar(require("./video-config"), exports);
exports.Experimental = {
    Clipper: Clipper_1.Clipper,
    Null: Null_1.Null,
    useIsPlayer: is_player_1.useIsPlayer,
};
