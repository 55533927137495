import { useCallback, useMemo } from 'react';
import { cancellablePromise } from './cancellable-promise';
import { delay } from './delay';
import { useCancellablePromises } from './use-cancellable-promises';
const useClickPreventionOnDoubleClick = (onClick, onDoubleClick, doubleClickToFullscreen) => {
    const api = useCancellablePromises();
    const handleClick = useCallback(async (e) => {
        api.clearPendingPromises();
        const waitForClick = cancellablePromise(delay(200));
        api.appendPendingPromise(waitForClick);
        try {
            await waitForClick.promise;
            api.removePendingPromise(waitForClick);
            onClick(e);
        }
        catch (errorInfo) {
            const info = errorInfo;
            api.removePendingPromise(waitForClick);
            if (!info.isCanceled) {
                throw info.error;
            }
        }
    }, [api, onClick]);
    const handleDoubleClick = useCallback(() => {
        api.clearPendingPromises();
        onDoubleClick();
    }, [api, onDoubleClick]);
    const returnValue = useMemo(() => {
        if (!doubleClickToFullscreen) {
            return [onClick, onDoubleClick];
        }
        return [handleClick, handleDoubleClick];
    }, [
        doubleClickToFullscreen,
        handleClick,
        handleDoubleClick,
        onClick,
        onDoubleClick,
    ]);
    return returnValue;
};
export { useClickPreventionOnDoubleClick };
