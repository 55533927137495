import { calculatePlayerSize } from './utils/calculate-player-size';
export const calculateScale = ({ canvasSize, compositionHeight, compositionWidth, previewSize, }) => {
    const heightRatio = canvasSize.height / compositionHeight;
    const widthRatio = canvasSize.width / compositionWidth;
    const ratio = Math.min(heightRatio, widthRatio);
    return previewSize === 'auto' ? ratio : Number(previewSize);
};
export const calculateCanvasTransformation = ({ previewSize, compositionWidth, compositionHeight, canvasSize, }) => {
    const scale = calculateScale({
        canvasSize,
        compositionHeight,
        compositionWidth,
        previewSize,
    });
    const correction = 0 - (1 - scale) / 2;
    const xCorrection = correction * compositionWidth;
    const yCorrection = correction * compositionHeight;
    const width = compositionWidth * scale;
    const height = compositionHeight * scale;
    const centerX = canvasSize.width / 2 - width / 2;
    const centerY = canvasSize.height / 2 - height / 2;
    return {
        centerX,
        centerY,
        xCorrection,
        yCorrection,
        scale,
    };
};
export const calculateOuterStyle = ({ config, style, canvasSize, }) => {
    if (!config) {
        return {};
    }
    return {
        position: 'relative',
        overflow: 'hidden',
        ...calculatePlayerSize({
            compositionHeight: config.height,
            compositionWidth: config.width,
            currentSize: canvasSize,
            height: style === null || style === void 0 ? void 0 : style.height,
            width: style === null || style === void 0 ? void 0 : style.width,
        }),
        ...style,
    };
};
export const calculateContainerStyle = ({ config, canvasSize, layout, scale, }) => {
    if (!config || !canvasSize || !layout) {
        return {};
    }
    return {
        position: 'absolute',
        width: config.width,
        height: config.height,
        display: 'flex',
        transform: `scale(${scale})`,
        marginLeft: layout.xCorrection,
        marginTop: layout.yCorrection,
        overflow: 'hidden',
    };
};
export const calculateOuter = ({ layout, scale, config, }) => {
    if (!layout || !config) {
        return {};
    }
    const { centerX, centerY } = layout;
    return {
        width: config.width * scale,
        height: config.height * scale,
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        left: centerX,
        top: centerY,
        overflow: 'hidden',
    };
};
