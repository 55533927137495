"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Audio = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const get_environment_1 = require("../get-environment");
const loop_1 = require("../loop");
const Sequence_1 = require("../Sequence");
const use_video_config_1 = require("../use-video-config");
const validate_media_props_1 = require("../validate-media-props");
const validate_start_from_props_1 = require("../validate-start-from-props");
const duration_state_1 = require("../video/duration-state");
const AudioForDevelopment_1 = require("./AudioForDevelopment");
const AudioForRendering_1 = require("./AudioForRendering");
const shared_audio_tags_1 = require("./shared-audio-tags");
const AudioRefForwardingFunction = (props, ref) => {
    var _a;
    const audioContext = (0, react_1.useContext)(shared_audio_tags_1.SharedAudioContext);
    const { startFrom, endAt, ...otherProps } = props;
    const { loop, ...propsOtherThanLoop } = props;
    const { fps } = (0, use_video_config_1.useVideoConfig)();
    const environment = (0, get_environment_1.useRemotionEnvironment)();
    const { durations, setDurations } = (0, react_1.useContext)(duration_state_1.DurationsContext);
    if (typeof props.src !== 'string') {
        throw new TypeError(`The \`<Audio>\` tag requires a string for \`src\`, but got ${JSON.stringify(props.src)} instead.`);
    }
    const onError = (0, react_1.useCallback)((e) => {
        console.log(e.currentTarget.error);
        throw new Error(`Could not play audio with src ${otherProps.src}: ${e.currentTarget.error}. See https://remotion.dev/docs/media-playback-error for help.`);
    }, [otherProps.src]);
    const onDuration = (0, react_1.useCallback)((src, durationInSeconds) => {
        setDurations({ type: 'got-duration', durationInSeconds, src });
    }, [setDurations]);
    if (loop && props.src && durations[props.src] !== undefined) {
        const duration = Math.floor(durations[props.src] * fps);
        const playbackRate = (_a = props.playbackRate) !== null && _a !== void 0 ? _a : 1;
        const actualDuration = duration / playbackRate;
        return ((0, jsx_runtime_1.jsx)(loop_1.Loop, { layout: "none", durationInFrames: Math.floor(actualDuration), children: (0, jsx_runtime_1.jsx)(exports.Audio, { ...propsOtherThanLoop, ref: ref }) }));
    }
    if (typeof startFrom !== 'undefined' || typeof endAt !== 'undefined') {
        (0, validate_start_from_props_1.validateStartFromProps)(startFrom, endAt);
        const startFromFrameNo = startFrom !== null && startFrom !== void 0 ? startFrom : 0;
        const endAtFrameNo = endAt !== null && endAt !== void 0 ? endAt : Infinity;
        return ((0, jsx_runtime_1.jsx)(Sequence_1.Sequence, { layout: "none", from: 0 - startFromFrameNo, showInTimeline: false, durationInFrames: endAtFrameNo, children: (0, jsx_runtime_1.jsx)(exports.Audio, { ...otherProps, ref: ref }) }));
    }
    (0, validate_media_props_1.validateMediaProps)(props, 'Audio');
    if (environment === 'rendering') {
        return ((0, jsx_runtime_1.jsx)(AudioForRendering_1.AudioForRendering, { onDuration: onDuration, ...props, ref: ref, onError: onError }));
    }
    return ((0, jsx_runtime_1.jsx)(AudioForDevelopment_1.AudioForDevelopment, { shouldPreMountAudioTags: audioContext !== null && audioContext.numberOfAudioTags > 0, ...props, ref: ref, onError: onError, onDuration: onDuration }));
};
exports.Audio = (0, react_1.forwardRef)(AudioRefForwardingFunction);
