import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from 'react';
import { Internals } from 'remotion';
import { getPreferredVolume, persistVolume } from './volume-persistance';
export const SharedPlayerContexts = ({ children, timelineContext, inputProps, fps, compositionHeight, compositionWidth, durationInFrames, component, numberOfSharedAudioTags, }) => {
    const compositionManagerContext = useMemo(() => {
        return {
            compositions: [
                {
                    component: component,
                    durationInFrames,
                    height: compositionHeight,
                    width: compositionWidth,
                    fps,
                    id: 'player-comp',
                    props: inputProps,
                    nonce: 777,
                    scale: 1,
                    folderName: null,
                    defaultProps: undefined,
                    parentFolderName: null,
                },
            ],
            folders: [],
            registerFolder: () => undefined,
            unregisterFolder: () => undefined,
            currentComposition: 'player-comp',
            registerComposition: () => undefined,
            registerSequence: () => undefined,
            sequences: [],
            setCurrentComposition: () => undefined,
            unregisterComposition: () => undefined,
            unregisterSequence: () => undefined,
            registerAsset: () => undefined,
            unregisterAsset: () => undefined,
            currentCompositionMetadata: null,
            setCurrentCompositionMetadata: () => undefined,
            assets: [],
            setClipRegion: () => undefined,
        };
    }, [
        component,
        durationInFrames,
        compositionHeight,
        compositionWidth,
        fps,
        inputProps,
    ]);
    const [mediaMuted, setMediaMuted] = useState(false);
    const [mediaVolume, setMediaVolume] = useState(getPreferredVolume());
    const mediaVolumeContextValue = useMemo(() => {
        return {
            mediaMuted,
            mediaVolume,
        };
    }, [mediaMuted, mediaVolume]);
    const setMediaVolumeAndPersist = useCallback((vol) => {
        setMediaVolume(vol);
        persistVolume(vol);
    }, []);
    const setMediaVolumeContextValue = useMemo(() => {
        return {
            setMediaMuted,
            setMediaVolume: setMediaVolumeAndPersist,
        };
    }, [setMediaVolumeAndPersist]);
    return (_jsx(Internals.CanUseRemotionHooksProvider, { children: _jsx(Internals.Timeline.TimelineContext.Provider, { value: timelineContext, children: _jsx(Internals.CompositionManager.Provider, { value: compositionManagerContext, children: _jsx(Internals.PrefetchProvider, { children: _jsx(Internals.DurationsContextProvider, { children: _jsx(Internals.MediaVolumeContext.Provider, { value: mediaVolumeContextValue, children: _jsx(Internals.SetMediaVolumeContext.Provider, { value: setMediaVolumeContextValue, children: _jsx(Internals.SharedAudioContextProvider, { numberOfAudioTags: numberOfSharedAudioTags, children: children }) }) }) }) }) }) }) }));
};
