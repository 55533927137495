"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VideoForDevelopment = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const use_audio_frame_1 = require("../audio/use-audio-frame");
const prefetch_1 = require("../prefetch");
const Sequence_1 = require("../Sequence");
const use_media_in_timeline_1 = require("../use-media-in-timeline");
const use_media_playback_1 = require("../use-media-playback");
const use_media_tag_volume_1 = require("../use-media-tag-volume");
const use_sync_volume_with_media_tag_1 = require("../use-sync-volume-with-media-tag");
const use_video_config_1 = require("../use-video-config");
const volume_position_state_1 = require("../volume-position-state");
const video_fragment_1 = require("./video-fragment");
const VideoForDevelopmentRefForwardingFunction = (props, ref) => {
    var _a, _b;
    const videoRef = (0, react_1.useRef)(null);
    const volumePropFrame = (0, use_audio_frame_1.useFrameForVolumeProp)();
    const { fps, durationInFrames } = (0, use_video_config_1.useVideoConfig)();
    const parentSequence = (0, react_1.useContext)(Sequence_1.SequenceContext);
    const { volume, muted, playbackRate, onlyWarnForMediaSeekingError, src, onDuration, 
    // @ts-expect-error
    acceptableTimeShift, acceptableTimeShiftInSeconds, ...nativeProps } = props;
    if (typeof acceptableTimeShift !== 'undefined') {
        throw new Error('acceptableTimeShift has been removed. Use acceptableTimeShiftInSeconds instead.');
    }
    const actualVolume = (0, use_media_tag_volume_1.useMediaTagVolume)(videoRef);
    const [mediaVolume] = (0, volume_position_state_1.useMediaVolumeState)();
    const [mediaMuted] = (0, volume_position_state_1.useMediaMutedState)();
    (0, use_media_in_timeline_1.useMediaInTimeline)({
        mediaRef: videoRef,
        volume,
        mediaVolume,
        mediaType: 'video',
        src,
        playbackRate: (_a = props.playbackRate) !== null && _a !== void 0 ? _a : 1,
    });
    (0, use_sync_volume_with_media_tag_1.useSyncVolumeWithMediaTag)({
        volumePropFrame,
        actualVolume,
        volume,
        mediaVolume,
        mediaRef: videoRef,
    });
    (0, use_media_playback_1.useMediaPlayback)({
        mediaRef: videoRef,
        src,
        mediaType: 'video',
        playbackRate: (_b = props.playbackRate) !== null && _b !== void 0 ? _b : 1,
        onlyWarnForMediaSeekingError,
        acceptableTimeshift: acceptableTimeShiftInSeconds !== null && acceptableTimeShiftInSeconds !== void 0 ? acceptableTimeShiftInSeconds : use_media_playback_1.DEFAULT_ACCEPTABLE_TIMESHIFT,
    });
    const actualFrom = parentSequence
        ? parentSequence.relativeFrom + parentSequence.cumulatedFrom
        : 0;
    const duration = parentSequence
        ? Math.min(parentSequence.durationInFrames, durationInFrames)
        : durationInFrames;
    const actualSrc = (0, video_fragment_1.useAppendVideoFragment)({
        actualSrc: (0, prefetch_1.usePreload)(src),
        actualFrom,
        duration,
        fps,
    });
    (0, react_1.useImperativeHandle)(ref, () => {
        return videoRef.current;
    }, []);
    (0, react_1.useEffect)(() => {
        const { current } = videoRef;
        if (!current) {
            return;
        }
        const errorHandler = () => {
            var _a;
            if (current === null || current === void 0 ? void 0 : current.error) {
                console.error('Error occurred in video', current === null || current === void 0 ? void 0 : current.error);
                throw new Error(`The browser threw an error while playing the video ${src}: Code ${current.error.code} - ${(_a = current === null || current === void 0 ? void 0 : current.error) === null || _a === void 0 ? void 0 : _a.message}. See https://remotion.dev/docs/media-playback-error for help`);
            }
            else {
                throw new Error('The browser threw an error');
            }
        };
        current.addEventListener('error', errorHandler, { once: true });
        return () => {
            current.removeEventListener('error', errorHandler);
        };
    }, [src]);
    const currentOnDurationCallback = (0, react_1.useRef)();
    currentOnDurationCallback.current = onDuration;
    (0, react_1.useEffect)(() => {
        var _a;
        const { current } = videoRef;
        if (!current) {
            return;
        }
        if (current.duration) {
            (_a = currentOnDurationCallback.current) === null || _a === void 0 ? void 0 : _a.call(currentOnDurationCallback, src, current.duration);
            return;
        }
        const onLoadedMetadata = () => {
            var _a;
            (_a = currentOnDurationCallback.current) === null || _a === void 0 ? void 0 : _a.call(currentOnDurationCallback, src, current.duration);
        };
        current.addEventListener('loadedmetadata', onLoadedMetadata);
        return () => {
            current.removeEventListener('loadedmetadata', onLoadedMetadata);
        };
    }, [src]);
    return ((0, jsx_runtime_1.jsx)("video", { ref: videoRef, muted: muted || mediaMuted, playsInline: true, src: actualSrc, ...nativeProps }));
};
// Copy types from forwardRef but not necessary to remove ref
exports.VideoForDevelopment = (0, react_1.forwardRef)(VideoForDevelopmentRefForwardingFunction);
