"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AudioForDevelopment = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const prefetch_1 = require("../prefetch");
const random_1 = require("../random");
const Sequence_1 = require("../Sequence");
const use_media_in_timeline_1 = require("../use-media-in-timeline");
const use_media_playback_1 = require("../use-media-playback");
const use_media_tag_volume_1 = require("../use-media-tag-volume");
const use_sync_volume_with_media_tag_1 = require("../use-sync-volume-with-media-tag");
const volume_position_state_1 = require("../volume-position-state");
const shared_audio_tags_1 = require("./shared-audio-tags");
const use_audio_frame_1 = require("./use-audio-frame");
const AudioForDevelopmentForwardRefFunction = (props, ref) => {
    const [initialShouldPreMountAudioElements] = (0, react_1.useState)(props.shouldPreMountAudioTags);
    if (props.shouldPreMountAudioTags !== initialShouldPreMountAudioElements) {
        throw new Error('Cannot change the behavior for pre-mounting audio tags dynamically.');
    }
    const [mediaVolume] = (0, volume_position_state_1.useMediaVolumeState)();
    const [mediaMuted] = (0, volume_position_state_1.useMediaMutedState)();
    const volumePropFrame = (0, use_audio_frame_1.useFrameForVolumeProp)();
    const { volume, muted, playbackRate, shouldPreMountAudioTags, src, onDuration, acceptableTimeShiftInSeconds, ...nativeProps } = props;
    if (!src) {
        throw new TypeError("No 'src' was passed to <Audio>.");
    }
    const preloadedSrc = (0, prefetch_1.usePreload)(src);
    const propsToPass = (0, react_1.useMemo)(() => {
        return {
            muted: muted || mediaMuted,
            src: preloadedSrc,
            ...nativeProps,
        };
    }, [mediaMuted, muted, nativeProps, preloadedSrc]);
    const sequenceContext = (0, react_1.useContext)(Sequence_1.SequenceContext);
    // Generate a string that's as unique as possible for this asset
    // but at the same time deterministic. We use it to combat strict mode issues.
    const id = (0, react_1.useMemo)(() => `audio-${(0, random_1.random)(src !== null && src !== void 0 ? src : '')}-${sequenceContext === null || sequenceContext === void 0 ? void 0 : sequenceContext.relativeFrom}-${sequenceContext === null || sequenceContext === void 0 ? void 0 : sequenceContext.cumulatedFrom}-${sequenceContext === null || sequenceContext === void 0 ? void 0 : sequenceContext.durationInFrames}-muted:${props.muted}`, [props.muted, src, sequenceContext]);
    const audioRef = (0, shared_audio_tags_1.useSharedAudio)(propsToPass, id).el;
    const actualVolume = (0, use_media_tag_volume_1.useMediaTagVolume)(audioRef);
    (0, use_sync_volume_with_media_tag_1.useSyncVolumeWithMediaTag)({
        volumePropFrame,
        actualVolume,
        volume,
        mediaVolume,
        mediaRef: audioRef,
    });
    (0, use_media_in_timeline_1.useMediaInTimeline)({
        volume,
        mediaVolume,
        mediaRef: audioRef,
        src,
        mediaType: 'audio',
        playbackRate: playbackRate !== null && playbackRate !== void 0 ? playbackRate : 1,
    });
    (0, use_media_playback_1.useMediaPlayback)({
        mediaRef: audioRef,
        src,
        mediaType: 'audio',
        playbackRate: playbackRate !== null && playbackRate !== void 0 ? playbackRate : 1,
        onlyWarnForMediaSeekingError: false,
        acceptableTimeshift: acceptableTimeShiftInSeconds !== null && acceptableTimeShiftInSeconds !== void 0 ? acceptableTimeShiftInSeconds : use_media_playback_1.DEFAULT_ACCEPTABLE_TIMESHIFT,
    });
    (0, react_1.useImperativeHandle)(ref, () => {
        return audioRef.current;
    }, [audioRef]);
    const currentOnDurationCallback = (0, react_1.useRef)();
    currentOnDurationCallback.current = onDuration;
    (0, react_1.useEffect)(() => {
        var _a;
        const { current } = audioRef;
        if (!current) {
            return;
        }
        if (current.duration) {
            (_a = currentOnDurationCallback.current) === null || _a === void 0 ? void 0 : _a.call(currentOnDurationCallback, src, current.duration);
            return;
        }
        const onLoadedMetadata = () => {
            var _a;
            (_a = currentOnDurationCallback.current) === null || _a === void 0 ? void 0 : _a.call(currentOnDurationCallback, src, current.duration);
        };
        current.addEventListener('loadedmetadata', onLoadedMetadata);
        return () => {
            current.removeEventListener('loadedmetadata', onLoadedMetadata);
        };
    }, [audioRef, src]);
    if (initialShouldPreMountAudioElements) {
        return null;
    }
    return (0, jsx_runtime_1.jsx)("audio", { ref: audioRef, ...propsToPass });
};
exports.AudioForDevelopment = (0, react_1.forwardRef)(AudioForDevelopmentForwardRefFunction);
