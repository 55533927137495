"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Null = void 0;
const react_1 = require("react");
const NativeLayers_1 = require("./NativeLayers");
const Null = () => {
    const { setClipRegion } = (0, react_1.useContext)(NativeLayers_1.NativeLayersContext);
    (0, react_1.useEffect)(() => {
        setClipRegion((c) => {
            if (c === null) {
                return 'hide';
            }
            // Rendering multiple <Null> is fine, because they are all hidden
            if (c === 'hide') {
                return 'hide';
            }
            throw new Error('Cannot render <Null>, because another component clipping the region was already rendered (most likely <Clipper>)');
        });
        return () => {
            setClipRegion(null);
        };
    }, [setClipRegion]);
    return null;
};
exports.Null = Null;
