"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Internals = void 0;
const config_1 = require("./config");
const shared_audio_tags_1 = require("./audio/shared-audio-tags");
const CanUseRemotionHooks_1 = require("./CanUseRemotionHooks");
const CompositionManager_1 = require("./CompositionManager");
const CSSUtils = __importStar(require("./default-css"));
const delay_render_1 = require("./delay-render");
const get_environment_1 = require("./get-environment");
const get_preview_dom_element_1 = require("./get-preview-dom-element");
const is_player_1 = require("./is-player");
const portal_node_1 = require("./portal-node");
const prefetch_state_1 = require("./prefetch-state");
const register_root_1 = require("./register-root");
const RemotionRoot_1 = require("./RemotionRoot");
const Sequence_1 = require("./Sequence");
const setup_env_variables_1 = require("./setup-env-variables");
const TimelinePosition = __importStar(require("./timeline-position-state"));
const truthy_1 = require("./truthy");
const use_lazy_component_1 = require("./use-lazy-component");
const use_unsafe_video_config_1 = require("./use-unsafe-video-config");
const use_video_1 = require("./use-video");
const validate_composition_id_1 = require("./validation/validate-composition-id");
const validate_dimensions_1 = require("./validation/validate-dimensions");
const validate_duration_in_frames_1 = require("./validation/validate-duration-in-frames");
const validate_fps_1 = require("./validation/validate-fps");
const validate_offthreadvideo_image_format_1 = require("./validation/validate-offthreadvideo-image-format");
const duration_state_1 = require("./video/duration-state");
const volume_position_state_1 = require("./volume-position-state");
const wrap_remotion_context_1 = require("./wrap-remotion-context");
const Timeline = TimelinePosition;
// Mark them as Internals so use don't assume this is public
// API and are less likely to use it
exports.Internals = {
    useUnsafeVideoConfig: use_unsafe_video_config_1.useUnsafeVideoConfig,
    Timeline,
    CompositionManager: CompositionManager_1.CompositionManager,
    RemotionRoot: RemotionRoot_1.RemotionRoot,
    useVideo: use_video_1.useVideo,
    getRoot: register_root_1.getRoot,
    useMediaVolumeState: volume_position_state_1.useMediaVolumeState,
    useMediaMutedState: volume_position_state_1.useMediaMutedState,
    useLazyComponent: use_lazy_component_1.useLazyComponent,
    truthy: truthy_1.truthy,
    SequenceContext: Sequence_1.SequenceContext,
    useRemotionContexts: wrap_remotion_context_1.useRemotionContexts,
    RemotionContextProvider: wrap_remotion_context_1.RemotionContextProvider,
    CSSUtils,
    setupEnvVariables: setup_env_variables_1.setupEnvVariables,
    ENV_VARIABLES_ENV_NAME: setup_env_variables_1.ENV_VARIABLES_ENV_NAME,
    MediaVolumeContext: volume_position_state_1.MediaVolumeContext,
    SetMediaVolumeContext: volume_position_state_1.SetMediaVolumeContext,
    validateDurationInFrames: validate_duration_in_frames_1.validateDurationInFrames,
    validateFps: validate_fps_1.validateFps,
    validateDimension: validate_dimensions_1.validateDimension,
    getRemotionEnvironment: get_environment_1.getRemotionEnvironment,
    SharedAudioContext: shared_audio_tags_1.SharedAudioContext,
    SharedAudioContextProvider: shared_audio_tags_1.SharedAudioContextProvider,
    invalidCompositionErrorMessage: validate_composition_id_1.invalidCompositionErrorMessage,
    isCompositionIdValid: validate_composition_id_1.isCompositionIdValid,
    getPreviewDomElement: get_preview_dom_element_1.getPreviewDomElement,
    compositionsRef: CompositionManager_1.compositionsRef,
    DELAY_RENDER_CALLSTACK_TOKEN: delay_render_1.DELAY_RENDER_CALLSTACK_TOKEN,
    portalNode: portal_node_1.portalNode,
    waitForRoot: register_root_1.waitForRoot,
    validateOffthreadVideoImageFormat: validate_offthreadvideo_image_format_1.validateOffthreadVideoImageFormat,
    CanUseRemotionHooksProvider: CanUseRemotionHooks_1.CanUseRemotionHooksProvider,
    CanUseRemotionHooks: CanUseRemotionHooks_1.CanUseRemotionHooks,
    enableLegacyRemotionConfig: config_1.enableLegacyRemotionConfig,
    PrefetchProvider: prefetch_state_1.PrefetchProvider,
    DurationsContextProvider: duration_state_1.DurationsContextProvider,
    IsPlayerContextProvider: is_player_1.IsPlayerContextProvider,
    useIsPlayer: is_player_1.useIsPlayer,
    useRemotionEnvironment: get_environment_1.useRemotionEnvironment,
};
