import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
const errorStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    height: '100%',
    width: '100%',
};
export class ErrorBoundary extends React.Component {
    constructor() {
        super(...arguments);
        this.state = { hasError: null };
    }
    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: error };
    }
    componentDidCatch(error) {
        this.props.onError(error);
    }
    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (_jsx("div", { style: errorStyle, children: this.props.errorFallback({
                    error: this.state.hasError,
                }) }));
        }
        return this.props.children;
    }
}
