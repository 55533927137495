import { useEffect, useState } from 'react';
export const useHoverState = (ref) => {
    const [hovered, stetHovered] = useState(false);
    useEffect(() => {
        const { current } = ref;
        if (!current) {
            return;
        }
        const onHover = () => {
            stetHovered(true);
        };
        const onLeave = () => {
            stetHovered(false);
        };
        current.addEventListener('mouseenter', onHover);
        current.addEventListener('mouseleave', onLeave);
        return () => {
            current.removeEventListener('mouseenter', onHover);
            current.removeEventListener('mouseenter', onLeave);
        };
    }, [ref]);
    return hovered;
};
