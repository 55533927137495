"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NativeLayersProvider = exports.NativeLayersContext = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
exports.NativeLayersContext = (0, react_1.createContext)({
    setClipRegion: () => {
        throw new Error('NativeLayers not set');
    },
    clipRegion: null,
});
const NativeLayersProvider = ({ children, }) => {
    const [clipRegion, setClipRegion] = (0, react_1.useState)(null);
    const context = (0, react_1.useMemo)(() => {
        return {
            setClipRegion,
            clipRegion,
        };
    }, [clipRegion, setClipRegion]);
    (0, react_1.useLayoutEffect)(() => {
        if (typeof window !== 'undefined') {
            window.remotion_getClipRegion = () => {
                return clipRegion;
            };
        }
    }, [clipRegion, setClipRegion]);
    return ((0, jsx_runtime_1.jsx)(exports.NativeLayersContext.Provider, { value: context, children: children }));
};
exports.NativeLayersProvider = NativeLayersProvider;
