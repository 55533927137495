import { useCallback, useMemo, useRef } from 'react';
const useCancellablePromises = () => {
    const pendingPromises = useRef([]);
    const appendPendingPromise = useCallback((promise) => {
        pendingPromises.current = [...pendingPromises.current, promise];
    }, []);
    const removePendingPromise = useCallback((promise) => {
        pendingPromises.current = pendingPromises.current.filter((p) => p !== promise);
    }, []);
    const clearPendingPromises = useCallback(() => pendingPromises.current.map((p) => p.cancel()), []);
    const api = useMemo(() => ({
        appendPendingPromise,
        removePendingPromise,
        clearPendingPromises,
    }), [appendPendingPromise, clearPendingPromises, removePendingPromise]);
    return api;
};
export { useCancellablePromises };
